<template>
  <div class="promptly-payment">
    <!-- 发票页 -->
    <div class="edition-center" v-show="!isShowPayment">
      <!-- 发票顶部 -->
      <div class="invoice-top">
        <div class="bx">
          <div class="topL">
            <b>{{ currency }}{{ total }}</b>
            <!-- 发票金额 -->
            <span>{{ $t("front-email-payment-Invoice-amount") }}</span>
            <div>
              <!-- 发票编号 -->
              <span
                >{{ $t("front-email-payment-Invoice-number") }}：{{
                  invoiceNumber
                }}</span
              >
              <span
                >{{ $t("label.quickbooks.viewlist.duedate") }}：{{
                  dueDate
                }}</span
              >
            </div>
          </div>
          <div class="topR">
            <div>
              <svg class="white" aria-hidden="true" @click="printpage">
                <use href="#icon-dayin2"></use>
              </svg>
              <svg class="white" aria-hidden="true" @click="download">
                <use href="#icon-down"></use>
              </svg>
            </div>
            <el-button type="primary" @click="toPayment">{{
              $t("front-email-payment-immediate-payment")
            }}</el-button>
          </div>
        </div>
      </div>
      <!-- 发票详情 -->
      <div class="invoice-details">
        <div class="invoice" v-loading="templatePdf == ''">
          <embed
            v-show="templatePdf != ''"
            :src="'data:application/pdf;base64,' + templatePdf + '#toolbar=0'"
            palette="red|black"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>

    <!-- 支付方式 -->
    <div class="payment-method" v-show="isShowPayment">
      <div class="payment-top">
        <svg class="fapiaoxinxi" aria-hidden="true">
          <use href="#icon-fapiaoxinxi"></use>
        </svg>
        <div class="imgR">
          <b>{{ $t("front-invoice-online-payment-information") }}</b>
          <div>
            <span
              >{{ $t("front-email-payment-Invoice-amount") }}：
              <b>{{ currency }}{{ total }}</b>
            </span>
            <!-- <span>{{ $t("label.quickbooks.invoices") }}：{{ total }}元</span> -->
            <!-- 发票编号 -->
            <span
              >{{ $t("front-email-payment-Invoice-number") }}：{{
                invoiceNumber
              }}</span
            >
            <span
              >{{ $t("label.same.analyze.account.name") }}：{{ customerName }}</span
            >
          </div>
        </div>
      </div>
      <div class="payment-content">
        <!-- 支付方式 -->
        <div>
          <span class="span1">{{
            $t("front-email-payment-Invoice-select-pay")
          }}</span>
          <el-tabs v-model="activeName">
            <!-- 支付宝 -->
            <el-tab-pane
              name="first"
              v-if="configuration.isalipay && currency === 'CNY'"
            >
              <span slot="label">
                <svg class="tabsImg" aria-hidden="true">
                  <use href="#icon-zhifubaozhifu"></use>
                </svg>
              </span>
              <div class="zfb">
                <span>{{
                  $t("front-email-payment-Invoice-login-account")
                }}</span>
                <p>
                  {{ $t("front-email-payment-Invoice-login-account-describe") }}
                </p>
                <div class="paypal-bottom">
                  <div class="bottomL">
                    <svg class="tabsImg1" aria-hidden="true">
                      <use href="#icon-zhifubaozhifu"></use>
                    </svg>

                    <div></div>
                    <span>{{
                      $t("front-email-payment-Invoice-alipay-describe")
                    }}</span>
                  </div>
                  <div class="bottomR">
                    <el-button type="primary" @click="creatOrder('ali')">{{
                      $t("label.goon")
                    }}</el-button>
                    <el-button @click="return1">{{
                      $t("label.userphoto.back")
                    }}</el-button>
                  </div>
                </div>
              </div>
              <div v-html="alipayWap" class="div1" ref="alipayWap"></div>
            </el-tab-pane>
            <!-- 微信 -->
            <el-tab-pane
              name="second"
              v-if="configuration.iswxpay && currency === 'CNY'"
            >
              <span slot="label">
                <svg
                  class="tabsImg"
                  aria-hidden="true"
                  @click="creatOrder('weixin')"
                >
                  <use href="#icon-weixinzhifu"></use>
                </svg>
              </span>
              <!-- 支付成功、失败 -->
              <div
                class="payState"
                v-if="activeName1 == 'second' && isShowPayState"
              >
                <!-- 成功 -->
                <div v-if="isShowSucc">
                  <span>
                    <svg class="statesvg" aria-hidden="true">
                      <use href="#icon-chenggong"></use>
                    </svg>
                    <b>{{ $t("label.partnerCloud.paySuccess") }}</b>
                  </span>
                  <div class="stateDetail">
                    <div>
                      <span>{{
                        $t("front-email-payment-Invoice-amount")
                      }}</span>
                      <b>{{ currency }}{{ total }}</b>
                    </div>
                    <div></div>
                    <div>
                      <!-- 发票编号 -->
                      <p>
                        {{ $t("front-email-payment-Invoice-number") }}:<span>{{
                          invoiceNumber
                        }}</span>
                      </p>
                      <p>
                        开票日期:<span>{{ invoiceDate }}</span>
                      </p>
                      <p>
                        {{ $t("label.quickbooks.viewlist.duedate") }}:<span>{{
                          dueDate
                        }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- 失败 -->
                <div v-if="!isShowSucc">
                  <span>
                    <svg class="statesvg" aria-hidden="true">
                      <use href="#icon-shibai1"></use>
                    </svg>
                    <b>{{ $t("front-email-payment-failed") }}</b>
                  </span>
                  <div class="stateDetail">
                    <div>
                      <span>{{
                        $t("front-email-payment-Invoice-amount")
                      }}</span>
                      <b>{{ currency }}{{ total }}</b>
                    </div>
                    <div></div>
                    <div>
                      <p>
                        {{ $t("front-email-payment-Invoice-number") }}:<span>{{
                          invoiceNumber
                        }}</span>
                      </p>
                      <p>
                        {{ $t("front-email-payment-billing-date") }}:<span>{{
                          invoiceDate
                        }}</span>
                      </p>
                      <p>
                        {{ $t("label.quickbooks.viewlist.duedate") }}:<span>{{
                          dueDate
                        }}</span>
                      </p>
                    </div>
                    <el-button type="primary" @click="isShowPayState = false">{{
                      $t("front-email-payment-repayments")
                    }}</el-button>
                  </div>
                </div>
              </div>
              <div class="wx" v-else>
                <div class="wxR">
                  <p>
                    <span
                      >{{ $t("front-email-payment-Invoice-amount") }}：</span
                    >
                    <b>CNY{{ total }}</b>
                  </p>
                  <div id="qrCode" ref="qrCodeDiv"></div>
                  <div class="wxRbottom">
                    <svg class="saoyisao" aria-hidden="true">
                      <use href="#icon-saoyisao"></use>
                    </svg>

                    <div>
                      <span>{{ $t("front-email-payment-weichat") }}</span>
                      <span>{{ $t("front-email-payment-weichat-tips") }}</span>
                    </div>
                  </div>
                </div>
                <div
                  style="width: 2px; height: 405px; background: #dedcda"
                ></div>
                <img src="../img/shouji.svg" alt="" />
              </div>
            </el-tab-pane>
            <!-- paypal -->
            <el-tab-pane name="third" v-if="configuration.ispaypol">
              <span slot="label">
                <svg class="tabsImg" aria-hidden="true">
                  <use href="#icon-a-Paypal1"></use>
                </svg>
              </span>
              <!-- 支付成功、失败 -->
              <div
                class="payState"
                v-if="activeName1 == 'third' && isShowPayState"
              >
                <!-- 成功 -->
                <div v-if="isShowSucc">
                  <span>
                    <svg class="statesvg" aria-hidden="true">
                      <use href="#icon-chenggong"></use>
                    </svg>
                    <b>{{ $t("label.partnerCloud.paySuccess") }}</b>
                  </span>
                  <div class="stateDetail">
                    <div>
                      <span>{{
                        $t("front-email-payment-Invoice-amount")
                      }}</span>
                      <b>{{ currency }}{{ total }}</b>
                    </div>
                    <div></div>
                    <div>
                      <!-- 发票编号 -->
                      <p>
                        {{ $t("front-email-payment-Invoice-number") }}:<span>{{
                          invoiceNumber
                        }}</span>
                      </p>
                      <p>
                        开票日期:<span>{{ invoiceDate }}</span>
                      </p>
                      <p>
                        {{ $t("label.quickbooks.viewlist.duedate") }}:<span>{{
                          dueDate
                        }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- 失败 -->
                <div v-if="!isShowSucc">
                  <span>
                    <svg class="statesvg" aria-hidden="true">
                      <use href="#icon-shibai1"></use>
                    </svg>
                    <b>{{ $t("front-email-payment-failed") }}</b>
                  </span>
                  <p>
                    <span v-if="errinfo">{{ errinfo }}</span>
                  </p>
                  <div class="stateDetail">
                    <div>
                      <span>{{
                        $t("front-email-payment-Invoice-amount")
                      }}</span>
                      <b>{{ currency }}{{ total }}</b>
                    </div>
                    <div></div>
                    <div>
                      <p>
                        {{ $t("front-email-payment-Invoice-number") }}:<span>{{
                          invoiceNumber
                        }}</span>
                      </p>
                      <p>
                        {{ $t("front-email-payment-billing-date") }}:<span>{{
                          invoiceDate
                        }}</span>
                      </p>
                      <p>
                        {{ $t("label.quickbooks.viewlist.duedate") }}:<span>{{
                          dueDate
                        }}</span>
                      </p>
                    </div>
                    <el-button type="primary" @click="isShowPayState = false">{{
                      $t("front-email-payment-repayments")
                    }}</el-button>
                  </div>
                </div>
              </div>
              <div class="paypal" v-else>
                <span>{{
                  $t("front-email-payment-Invoice-login-account")
                }}</span>
                <p>
                  {{ $t("front-email-payment-paypal-describe") }}
                </p>
                <div class="paypal-bottom">
                  <div class="bottomL">
                    <svg class="paypal1" aria-hidden="true">
                      <use href="#icon-a-paypal1"></use>
                    </svg>
                    <div class="xian"></div>
                    <span>{{
                      $t("front-email-payment-Invoice-alipay-describe")
                    }}</span>
                  </div>
                  <div class="bottomR">
                    <el-button type="primary" @click="creatOrder('paypol')">{{
                      $t("label.goon")
                    }}</el-button>
                    <el-button @click="return1">{{
                      $t("label.userphoto.back")
                    }}</el-button>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <!-- visa -->
            <el-tab-pane name="fourth" v-if="configuration.isvisa">
              <span slot="label">
                <svg class="tabsImg1" aria-hidden="true">
                  <use href="#icon-a-Visa1"></use>
                </svg>
              </span>
              <!-- 支付成功、失败 -->
              <div
                class="payState"
                v-if="activeName1 == 'fourth' && isShowPayState"
              >
                <!-- 成功 -->
                <div v-if="isShowSucc">
                  <span>
                    <svg class="statesvg" aria-hidden="true">
                      <use href="#icon-chenggong"></use>
                    </svg>
                    <b>{{ $t("label.partnerCloud.paySuccess") }}</b>
                  </span>
                  <div class="stateDetail">
                    <div>
                      <span>{{
                        $t("front-email-payment-Invoice-amount")
                      }}</span>
                      <b>{{ currency }}{{ total }}</b>
                    </div>
                    <div></div>
                    <div>
                      <!-- 发票编号 -->
                      <p>
                        {{ $t("front-email-payment-Invoice-number") }}:<span>{{
                          invoicenumber
                        }}</span>
                      </p>
                      <p>
                        开票日期:<span>{{ invoiceDate }}</span>
                      </p>
                      <p>
                        {{ $t("label.quickbooks.viewlist.duedate") }}:<span>{{
                          dueDate
                        }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- 失败 -->
                <div v-if="!isShowSucc">
                  <span>
                    <svg class="statesvg" aria-hidden="true">
                      <use href="#icon-shibai1"></use>
                    </svg>
                    <b>{{ $t("front-email-payment-failed") }}</b>
                  </span>
                  <p>
                    <span v-if="errinfoV">{{ errinfoV }}</span>
                  </p>
                  <div class="stateDetail">
                    <div>
                      <span>{{
                        $t("front-email-payment-Invoice-amount")
                      }}</span>
                      <b>{{ currency }}{{ total }}</b>
                    </div>
                    <div></div>
                    <div>
                      <p>
                        {{ $t("front-email-payment-Invoice-number") }}:<span>{{
                          invoiceNumber
                        }}</span>
                      </p>
                      <p>
                        {{ $t("front-email-payment-billing-date") }}:<span>{{
                          invoiceDate
                        }}</span>
                      </p>
                      <p>
                        {{ $t("label.quickbooks.viewlist.duedate") }}:<span>{{
                          dueDate
                        }}</span>
                      </p>
                    </div>
                    <el-button type="primary" @click="isShowPayState = false">{{
                      $t("front-email-payment-repayments")
                    }}</el-button>
                  </div>
                </div>
              </div>
              <div class="visa" v-else>
                <div class="visaTop">
                  <svg class="Visa1" aria-hidden="true">
                    <use href="#icon-a-Visa1"></use>
                  </svg>
                  <i></i>
                  <div>
                    <span
                      >{{ $t("front-email-payment-Invoice-amount") }}：</span
                    >
                    <div>{{ currency }}{{ total }}</div>
                  </div>
                </div>
                <div class="visaForm">
                  <el-form
                    label-position="right"
                    label-width="80px"
                    :model="visaForm"
                    :inline="true"
                    :rules="visaRules"
                    ref="visaForm"
                  >
                    <el-form-item
                      :label="$t('front-email-payment-card-number')"
                      prop="cardNumber"
                    >
                      <el-input
                        v-model="visaForm.cardNumber"
                        style="width: 464px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('AD')" prop="address">
                      <el-input
                        v-model="visaForm.address"
                        style="width: 464px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('label.usefullife')" prop="time1">
                      <el-col :span="12">
                        <el-form-item prop="YY">
                          <el-date-picker
                            v-model="visaForm.YY"
                            type="year"
                            :placeholder="
                              $t('front-email-payment-select-date-tips')
                            "
                            format="yyyy"
                            value-format="yyyy"
                            style="width: 216px"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="1"> / </el-col>
                      <el-col :span="8">
                        <el-form-item prop="MM">
                          <el-date-picker
                            v-model="visaForm.MM"
                            type="month"
                            :placeholder="
                              $t('front-email-payment-select-month-tips')
                            "
                            format="MM"
                            value-format="MM"
                            style="width: 216px"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                    </el-form-item>
                    <el-form-item :label="$t('label.country')" prop="country">
                      <el-input
                        v-model="visaForm.country"
                        style="width: 464px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$t('front-email-payment-safety-code')"
                      prop="safetyCode"
                    >
                      <el-input
                        v-model="visaForm.safetyCode"
                        style="width: 464px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('label.city')" prop="city">
                      <el-input
                        v-model="visaForm.city"
                        style="width: 464px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$t('label.sales.dashboard.name')"
                      prop="name"
                    >
                      <el-input
                        v-model="visaForm.name"
                        style="width: 464px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$t('front-email-payment-area')"
                      prop="area"
                    >
                      <el-input
                        v-model="visaForm.area"
                        style="width: 464px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$t('label.name.lastname')"
                      prop="surname"
                    >
                      <el-input
                        v-model="visaForm.surname"
                        style="width: 464px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$t('System.AddressFieldType.Post')"
                      prop="zipCode"
                    >
                      <el-input
                        v-model="visaForm.zipCode"
                        style="width: 464px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$t('label.weixin.telephone')"
                      prop="phoneNumber"
                    >
                      <el-input
                        v-model="visaForm.phoneNumber"
                        style="width: 464px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="$t('label.emailsync.form.email')"
                      prop="email"
                    >
                      <el-input
                        v-model="visaForm.email"
                        style="width: 464px"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
                <div class="btn" @click="submitForm('visaForm')">
                  <svg class="topay" aria-hidden="true">
                    <use href="#icon-a-topay"></use>
                  </svg>
                  <span>{{ $t("front-email-payment-immediate-payment") }}</span>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import * as invoicePayApi from "../api.js";

export default {
  name: "",
  data() {
    return {
      isShowPayState: false,
      isShowSucc: false,
      alipayWap: "",
      configuration: {}, // 无token查询发票在线支付配置    是否开启 isalipay;iswxpay;ispaypol;isvisa;
      isShowPayment: false,
      activeName: "first",
      activeName1: "",
      visaForm: {
        cardNumber: "",
        address: "",
        // validity: "",
        YY: "",
        MM: "",
        country: "",
        safetyCode: "",
        city: "",
        name: "",
        area: "",
        surname: "",
        zipCode: "",
        phoneNumber: "",
        email: "",
        time1: "1",
      },
      visaRules: {
        // validity: [{ required: true }],
        cardNumber: [
          {
            required: true,
            message: this.$i18n.t("front-email-payment-card-tips"),
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: this.$i18n.t("label.partnerCloud.enterAddress"),
            trigger: "blur",
          },
        ],
        YY: [
          {
            required: true,
            message: this.$i18n.t("front-email-payment-select-date-tips"),
            trigger: "change",
          },
        ],
        MM: [
          {
            required: true,
            message: this.$i18n.t("front-email-payment-select-month-tips"),
            trigger: "change",
          },
        ],
        country: [
          {
            required: true,
            message: this.$i18n.t("front-email-payment-select-country"),
            trigger: "blur",
          },
        ],
        safetyCode: [
          {
            required: true,
            message: this.$i18n.t("label.login.input.verification.code"),
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: this.$i18n.t("label.attendance.cityname"),
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: this.$i18n.t("vue_label_calendar_please_enter_the_name"),
            trigger: "blur",
          },
        ],
        area: [
          {
            required: true,
            message: this.$i18n.t("front-email-payment-select-area"),
            trigger: "blur",
          },
        ],
        surname: [
          {
            required: true,
            message: this.$i18n.t("label.weixin.input.username"),
            trigger: "blur",
          },
        ], //请输入姓氏
        zipCode: [
          {
            required: true,
            message: this.$i18n.t("front-email-payment-select-email"),
            trigger: "blur",
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$i18n.t("label.weixin.input.telephone"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$i18n.t("label.attendance.cityname"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$i18n.t(
              "vue_label_commonobjects_detail_correct_email_address"
            ),
            trigger: ["blur", "change"],
          },
        ],
        time1: [{ required: true }],
      },
      //金额
      total: this.$route.query.total,
      //发票编号
      invoiceNumber: this.$route.query.invoiceNumber,
      //开票日期
      invoiceDate: this.$route.query.invoiceDate,
      //到期日期
      dueDate: this.$route.query.dueDate,
      //币种类型
      currency: this.$route.query.currency,
      //发票信息
      templatePdf: "",
      // 客户名称
      username: this.$route.query.loginName,
      customerName:this.$route.query.customerName,
      // 发票id
      invoiceid: this.$route.query.recordId,
      //
      returnCode: 2,
      errinfo: "",
      errinfoV: "",
    };
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
    this.invoicePreview();
  },
  methods: {
    //下载附件
    download() {
      const baseURL = this.$baseConfig.baseURL;
      const link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadFileNoToken?id=${this.$route.query.fileId}&userid=${this.$route.query.userId}&username=${this.$route.query.loginName}&orgid=${this.$route.query.orgId}`;
      link.click();
    },
    //打印
    printpage() {
      window.open(
        `#/print-invoices/${this.$route.query.objId}/${this.$route.query.recordId}/${this.$route.query.templateId}/Invoices/${this.$route.query.userId}/${this.$route.query.loginName}/${this.$route.query.orgId}`,
        "_parent - URL",
        "height=700, width=1300, top=50, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      );
    },
    //获取发票预览
    async invoicePreview() {
      let result = await invoicePayApi.getTemplatePdfNoToken({
        id: this.$route.query.templateId,
        objid: this.$route.query.objId,
        recordId: this.$route.query.recordId,
        type: "Invoices",
        userid: this.$route.query.userId,
        username: this.$route.query.loginName,
        orgid: this.$route.query.orgId,
      });
      if (result.data.result) {
        this.templatePdf = result.data.data.templatePdf;
        // 
      }
    },
    // 生成二维码
    bindQRCode: function (codeurl) {
      // 
      let dom = document.getElementById("qrCode");
      if (dom.firstChild) {
        // 删除所有子节点
        while (dom.firstChild) {
          dom.removeChild(dom.firstChild);
        }
      }
      new QRCode(this.$refs.qrCodeDiv, {
        text: codeurl,
        width: 220,
        height: 220,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visaPay();
        } else {
          
          return false;
        }
      });
    },
    toPayment() {
      let params = {
        invoiceid: this.invoiceid,
        userid: this.$route.query.userId,
        username: this.$route.query.loginName,
        orgid: this.$route.query.orgId,
      };
      // 获取发票回款状态
      invoicePayApi.getPayStatus(params).then((res) => {
        // 全部回款时不走支付页面
        if(res.data.returnCode == '1') {
          this.$message({
            showClose: true,
            message: '重复支付提醒：您已在该商家完成金额相同的交易，请勿重复支付',
            type: "warning",
            duration: 6000,
          });
        } else {
          // 判断币种，如果不是人民币，paypal和visa都没开通提示"请联系管理员，开通PayPal或VISA后，在发起线上付款"
          setTimeout(() => {
            // 加个延时器不然可能会报错
            if (this.currency !== "CNY") {
              if (this.configuration.ispaypol || this.configuration.isvisa) {
                this.isShowPayment = true;
              } else {
                this.$message({
                  showClose: true,
                  message: this.$i18n.t("front-email-payment-Invoice-Tips"),
                  type: "error",
                  duration: 6000,
                });
              }
            } else {
              if (
                this.configuration.ispaypol ||
                this.configuration.isvisa ||
                this.configuration.iswxpay ||
                this.configuration.isalipay
              ) {
                this.isShowPayment = true;
              } else {
                this.$message({
                  showClose: true,
                  message: this.$i18n.t("front-email-payment-Invoice-Tips1"),
                  type: "error",
                  duration: 6000,
                });
              }
            }
          }, 1000);
        }
      })
    },
    // 返回发票预览
    return1() {
      this.isShowPayment = false;
    },

    // 无token查询发票在线支付配置
    getEmailOnlinePaySetUpNotoken() {
      let params = {
        userid: this.$route.query.userId,
        username: this.$route.query.loginName,
        orgid: this.$route.query.orgId,
      };
      invoicePayApi.getEmailOnlinePaySetUpNotoken(params).then((res) => {
        this.configuration = res.data.data;

        //字符串--->布尔值
        this.configuration.isalipay = JSON.parse(this.configuration.isalipay);
        this.configuration.iswxpay = JSON.parse(this.configuration.iswxpay);
        this.configuration.ispaypol = JSON.parse(this.configuration.ispaypol);
        this.configuration.isvisa = JSON.parse(this.configuration.isvisa);
        // activeName不对问题 first second third fourth
        if (!this.isShowPayState) {
          if (this.configuration.isalipay && this.currency === "CNY") {
            this.activeName = "first";
          } else if (this.configuration.iswxpay && this.currency === "CNY") {
            this.activeName = "second";
          } else if (this.configuration.ispaypol) {
            this.activeName = "third";
          } else if (this.configuration.isvisa) {
            this.activeName = "fourth";
          }
        }
        // 
      });
    },
    // 支付宝/微信/paypol支付接口
    creatOrder(paytype) {
      let params = {
        invoiceid: this.invoiceid,
        userid: this.$route.query.userId,
        username: this.$route.query.loginName,
        orgid: this.$route.query.orgId,
        paytype: paytype,
      };
      invoicePayApi.creatOrder(params).then((res) => {
        // 
        if (paytype == "paypol") {
          // paypal支付
          window.open(res.data.data.approvalUrl);
        } else if (paytype == "ali") {
          // 支付宝
          // ///////////////////
          // 不知道什么原因第一次跳本页而且多个？
          // let divForm = document.getElementsByTagName("div");
          // if (divForm.length) {
          //   document.body.removeChild(divForm[0]);
          // }
          // //  res.data.body默认是我们拿到的form代码
          // const div = document.createElement("div");
          // div.innerHTML = res.data.body;
          // document.body.appendChild(div);
          // // document.getElementsByClassName("div1").appendChild(div);
          // document.forms[0].setAttribute("target", "_blank"); // 加了_blank可能出问题所以我注释了
          //   document.forms[0].submit(); //重要，这个才是点击跳页面的核心
          // ///////////////////
          this.alipayWap = res.data.data.body;
          this.$nextTick(() => {
            document.forms[0].setAttribute("target", "_blank");
            this.$refs.alipayWap.children[0].submit();
          });
          // ///////////////////
        } else if (paytype == "weixin") {
          // 微信
          if (
            res.data.data != null &&
            res.data.data != undefined &&
            res.data.data != ""
          ) {
            if (res.data.data.err_code_des) {
              // 已支付提示
              this.$message({
                showClose: true,
                message: res.data.data.err_code_des,
                type: "error",
                duration: 6000,
              });
            } else {
              this.bindQRCode(res.data.data.code_url);
              let timer = setInterval(() => {
                // 查询支付状态
                this.getPayStatus();
                if (this.activeName != "second") {
                  clearInterval(timer);
                }

                if (this.returnCode == 0) {
                  clearInterval(timer);
                  //付款失败
                  this.$nextTick(() => {
                    this.isShowPayState = true;
                    this.isShowSucc = false;
                  });
                } else if (this.returnCode == 1) {
                  clearInterval(timer);
                  // 付款成功
                  this.$nextTick(() => {
                    this.isShowPayState = true;
                    this.isShowSucc = true;
                  });
                }
              }, 3000);
            }
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("front-email-payment-error-tips"),
              type: "error",
              duration: 6000,
            });
          }
        }
      });
    },

    // visa支付
    
    visaPay() {
      let params = {
        // 表单收集的数据
        cardNumber: this.visaForm.cardNumber, //卡号
        expirationMonth: this.visaForm.MM, //到期月
        expirationYear: this.visaForm.YY, //到期年
        firstName: this.visaForm.name, //名
        lastName: this.visaForm.surname, //姓
        phoneNumber: this.visaForm.phoneNumber, //手机号
        securityCode: this.visaForm.safetyCode, //
        country: this.visaForm.country, //国家
        locality: this.visaForm.city, //卡申请城市
        administrativeArea: this.visaForm.area, //行政区
        address1: this.visaForm.address, //地址
        postalCode: this.visaForm.zipCode, //邮编
        email: this.visaForm.email, //邮件
        // 、、、、、、、、、、、、、
        // cardNumber: "4111111111111111", //卡号
        // expirationMonth: "12", //到期月
        // expirationYear: "2031", //到期年
        // firstName: "John", //名
        // lastName: "Doe", //姓
        // phoneNumber: "4158880000", //手机号
        // securityCode: "123", //
        // country: "US", //国家
        // locality: "san francisco", //卡申请城市
        // administrativeArea: "CA", //行政区
        // address1: "1 Market St", //地址
        // postalCode: "94105", //邮编
        // email: "test@cybs.com", //邮件

        //发票信息
        invoiceid: this.invoiceid,
        userid: this.$route.query.userId,
        username: this.$route.query.loginName,
        orgid: this.$route.query.orgId,
      };
      invoicePayApi.visaPay(params).then((res) => {
        this.activeName1 = "fourth";
        if (res.data.data != null) {
          this.isShowPayState = true;
          this.isShowSucc = true;
        } else {
          this.isShowPayState = true;
          this.isShowSucc = false;
          this.errinfoV = res.data.returnInfo;
        }
      });
    },
    // 轮询查询支付状态
    
    getPayStatus() {
      let params = {
        invoiceid: this.invoiceid,
        userid: this.$route.query.userId,
        username: this.$route.query.loginName,
        orgid: this.$route.query.orgId,
      };
      invoicePayApi.getPayStatus(params).then((res) => {
        
        this.returnCode = res.data.returnCode;
        if (this.returnCode != "2") {
          this.activeName1 = "second";
        }
      });
    },
    // paypal查询支付状态
    // 根据发票的id获取邮件所需信息无token
    GetMessageByNumNoToken() {
      this.invoiceid = this.$route.query.invoiceid;
      let params = {
        invoiceid: this.invoiceid,
        userid: this.$route.query.userId,
        username: this.$route.query.loginName,
        orgid: this.$route.query.orgId,
      };
      invoicePayApi.GetMessageByNumNoToken(params).then((res) => {
        // 
        // let a = JSON.parse(res.data.data);
        

        if (res.data.data) {
          //金额
          this.total = res.data.data.total;
          //发票编号
          this.invoiceNumber = res.data.data.invoicenumber;
          //开票日期
          this.invoiceDate = res.data.data.invoiceDate;
          //到期日期
          this.dueDate = res.data.data.dueDate;
          //币种类型
          this.currency = res.data.data.currency;
          // 客户名称
          this.username = res.data.data.username;
          // 发票id
          this.invoiceid = res.data.data.invoiceid;
        }
      });
    },
  },
  created() {
    this.errinfo = this.$route.query.errinfo;
    this.getEmailOnlinePaySetUpNotoken();

    // 获取页面链接
    // 
    // 判断是否是支付后跳转的      payState支付状态
    if (
      this.$route.query.payState != undefined &&
      this.$route.query.payState != null &&
      this.$route.query.payState != ""
    ) {
      this.isShowPayment = true;
      this.activeName1 = "third";
      if (this.$route.query.payState == "true") {
        this.isShowSucc = true;
        this.isShowPayState = true;
      } else {
        this.isShowPayState = true;
        this.isShowSucc = false;
      }
      this.GetMessageByNumNoToken();
      this.activeName = "third";
    }
  },
};
</script>
<style lang="scss" scoped>
v-deep .el-form-item__label {
  font-size: 16px;
  color: #444444;
}
.promptly-payment {
  height: 100%;
  .edition-center {
    .invoice-top {
      background: #eff0f1;
      .bx {
        height: 155px;
        width: 1225px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .topL {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          b {
            font-size: 32px;
            font-weight: bold;
            color: #ff9e2c;
          }
          span {
            font-size: 18px;
            color: #333333;
          }
          & > div {
            font-size: 18px;
            color: #333333;
            span {
              margin-right: 56px;
            }
          }
        }
        .topR {
          display: flex;
          align-items: center;
          .el-button {
            margin-left: 16px;
            height: 50px;
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
    }
    .invoice-details {
      padding: 29px 0;
      .invoice {
        width: 1225px;
        margin: 0 auto;
        height: 914px;
        background: #ffffff;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
      }
    }
    .white {
      background: #fff;
      width: 22px;
      height: 22px;
      border: 1px solid #dedcda;
      display: inline-block;
      text-align: center;
      line-height: 48px;
      padding: 13px;
      cursor: pointer;
    }
  }
  .payment-method {
    height: 100%;
    background: #f0f0f0;
    padding: 12px;
    .payment-top {
      display: flex;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      height: 95px;
      .fapiaoxinxi {
        width: 52px;
        height: 52px;
        margin-right: 20px;
      }
      img {
        margin-right: 17px;
      }
      .imgR {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        b {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
        & > div {
          width: 700px;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          color: #333333;
          & > :nth-child(1) {
            b {
              color: #ff9e2c;
              font-weight: 400;
            }
          }
        }
      }
    }
    .payment-content {
      padding: 10px 36px 20px 36px;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      margin-top: 16px;
      min-height: calc(100% - 95px - 16px);
      display: grid;
      .payState {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          & > span {
            font-size: 24px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            b {
              margin-left: 24px;
            }
          }
          & > p {
            margin-bottom: 33px;
            /* color: #ff9e2c; */
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            width: 586px;
            span {
              text-align: center;
              width: 586px;
              display: block;
            }
          }
          .stateDetail {
            width: 586px;
            height: 332px;
            background: #f3f8fe;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            & > :nth-child(1) {
              display: flex;
              flex-direction: column;
              align-items: center;
              span {
                font-size: 18px;
                font-weight: bold;
                color: #333333;
                margin-bottom: 20px;
              }
              b {
                font-size: 24px;
                font-weight: bold;

                color: #ff9e2c;
              }
              span {
                color: #333333;
              }
            }

            & > :nth-child(2) {
              width: 565px;
              height: 1px;
              opacity: 1;
              border: 1px solid #006dd3;
            }

            & > :nth-child(3) {
              p {
                margin-bottom: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                span {
                  margin-left: 44px;
                }
              }
            }
          }
        }
      }
      .span1 {
        position: absolute;
        font-size: 16px;
        top: 150px;
      }
      ::v-deep .el-tabs__nav {
        left: 150px;
      }
    }
  }
}
.paypal,
.zfb {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  & > span {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
  p {
    font-size: 16px;
    color: #333333;
    margin: 32px 0 61px 0;
  }
  .paypal-bottom {
    display: flex;
    justify-content: center;
    height: 120px;
    line-height: 120px;
    .bottomL {
      display: flex;
      align-items: center;
      margin-right: 60px;
      div {
        width: 1px;
        height: 44px;
        background: #979797;
        margin: 0 16px 0 26px;
      }
      .xian {
        width: 1px;
        height: 44px;
        background: #979797;
        margin: 0 18px 0 -18px;
      }

      .paypal1 {
        width: 170px;
        height: 110px;
        margin-top: 20px;
      }
    }
  }
}
.statesvg {
  width: 64px;
  height: 64px;
}
.wx {
  margin: 100px auto 0;
  width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .wxR {
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      b {
        font-size: 24px;
        font-weight: bold;
        color: #ff9e2c;
      }
    }

    .wxRbottom {
      width: 330px;
      height: 82px;
      background: #44af35;
      border-radius: 4px 4px 4px 4px;
      padding: 0 46px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .saoyisao {
        width: 40px;
        height: 40px;
      }
      div {
        span {
          display: block;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  & > img {
    width: 250px;
  }
}
.visa {
  display: flex;
  flex-direction: column;
  align-items: center;
  .visaTop {
    display: flex;
    align-items: center;
    padding-top: 100px;
    .Visa1 {
      height: 60px;
      width: 100px;
    }
    i {
      width: 1px;
      height: 65px;
      background: #979797;
      margin: 0 47px;
    }
    & > div {
      span {
        font-size: 16px;
        color: #333333;
      }
      div {
        font-size: 30px;
        font-weight: bold;
        color: #ff9e2c;
      }
    }
  }
  .visaForm {
    margin: 50px 0;
    .el-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1200px;
    }
  }
  .btn {
    font-size: 21px;
    font-weight: 500;
    color: #ffffff;
    width: 200px;
    height: 60px;
    border-radius: 4px;
    background: #006dd3;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .topay {
      height: 30px;
      width: 50px;
      margin-right: 10px;
    }
  }
}
.tabsImg {
  margin-bottom: 10px;
  height: 42px;
  width: 144px;
}
.tabsImg1 {
  height: 65px;
  width: 100px;
}
</style>
